interface schoolGrantGuidelinesConfigProps {
    schoolType: string;
    value: string;
}

export function schoolGrantGuidelinesConfig (
    { schoolType, value }: schoolGrantGuidelinesConfigProps
) {
    return [{
        name: "guidelines",
        elements: [
            {
                type: "html",
                html: `
<h3>Award - ${ schoolType }</h3>
<div>
  <ol>
    <li>
      ${ value } to assist with school fees, donations, course costs, extra-curricular
      learning activity, e.g. Kip McGrath
    </li>
    <li>One award per member per annum</li>
  </ol>
</div>
<br/>
<h3>Eligibility</h3>
<div>
  Open to all ${schoolType.toLowerCase()} school students who are:
  <ol>
    <li>Registered with Whakarewa</li>
    <li>
      Enrolled at a New Zealand ${schoolType.toLowerCase()} school (including kura kaupapa, charter
      and correspondence schools)
    </li>
    <li>
      Provide evidence of enrolment or supporting documentation for costs for
      current year
    </li>
  </ol>
</div>
<br/>
<h3>Other Requirements</h3>
<div>
  <ol>
    <li>
      Whānau and recipients will be required to make themselves available for
      interviews and Whakarewa promotional activity
    </li>
  </ol>
</div>
                `,
            },
        ],
    }];
}

interface schoolConfigProps {
    children: {
        id: number;
        firstName: string;
        surname: string;
    }[];
    parent: string;
}

export interface SchoolData {
    school: string;
    schoolAddress: string;
    schoolFeeUse: string;
    applicant?: string;
    schoolFeesReceipt?: File[];
    schoolPhoto?: File[];
}
export function schoolConfig({ children, parent }: schoolConfigProps) {
    const hasTamariki = children.length ? "true" : "false";
    const childChoices = children.map(
        ({ id, firstName, surname }) => ({
            value: `${id} ${firstName} ${surname}`,
            text: `${firstName} ${surname}`,
        })
    );
    childChoices.unshift({value: parent, text: parent})

    return [{
            name: "childApplicant",
            title: "Applicant",
            visibleIf: hasTamariki,
            questions: [
                {
                    type: "dropdown",
                    name: "applicant",
                    title: "This Application is for",
                    isRequired: true,
                    choices: childChoices,
                }
            ]
        }, {
            name: "school",
            title: "School - Te Kura",
            questions: [
                {
                    type: "text",
                    name: "school",
                    title: "Name School",
                    placeHolder: "Ingoa o te Kura",
                    isRequired: true,
                },
                {
                    type: "comment",
                    name: "schoolAddress",
                    title: "School Address",
                    placeHolder: "Wāhi Noho o te Kura",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "schoolFeeUse",
                    title: "How will this funding be used (e.g. school fees, books, etc...)",
                    placeHolder: "Whakamahi",
                    startWithNewLine: true,
                    isRequired: true,
                },
            ],
        },
        {
            name: "files",
            title: "Uploads / Requirements",
            questions: [
                {
                    type: "file",
                    name: "schoolFeesReceipt",
                    title: "Official enrolment proof or fees receipt for current year",
                    storeDataAsText: false,
                    allowMultiple: false,
                    maxSize: 10485760,
                    waitForUpload: true,
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "file",
                    name: "schoolPhoto",
                    title: "Photo - for use on social media, pānui and website articles",
                    storeDataAsText: false,
                    allowMultiple: false,
                    maxSize: 10485760,
                    waitForUpload: true,
                    startWithNewLine: true,
                    isRequired: true,
                },
            ],
        },
    ];
}