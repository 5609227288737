import { PersonRequest } from "@plinknz/tah-website-elements";

import { equalish } from "../boolean-functions";
import { getPhone, PhoneType } from "../phone";
import type { SVGLine } from "./svg";
import { AddressMeta } from "../../components/form/address-finder-input";

interface getPersonalChangesProps {
    member: PersonRequest;
    phone: string;
    email: string;
    addressfinder: AddressMeta;
}
export function getPersonalChanges({
    member,
    phone,
    email,
    addressfinder,
}: getPersonalChangesProps){
    const personalChanges: SVGLine[] = [{heading: "Contact Changes"}];
    const existingCell = getPhone({ phone: member.phone, type: PhoneType.Cell });
    const existingPhone = getPhone({ phone: member.phone, type: PhoneType.Home });

    if (
        !equalish(phone, existingCell) &&
        !equalish(phone, existingPhone)
    ) {
        personalChanges.push({label: "Phone", text: phone });
    }
    
    if (!equalish(email, member.email[0]?.value)){
        personalChanges.push({label: "Email", text: email });
    }
    
    if (addressfinder){
        const existing = member.address[0];
        
        if (
            (!existing && addressfinder.address_line_1) || 
            !equalish(existing.line1, addressfinder.address_line_1) ||
            !equalish(existing.line2, addressfinder.address_line_2) ||
            !equalish(existing.city, addressfinder.city) ||
            !equalish(existing.postcode, addressfinder.postcode)
        ) {
            let newAddress: string[] = [];
            [
                addressfinder.address_line_1,
                addressfinder.address_line_2,
                addressfinder.suburb,
                `${addressfinder.city}${addressfinder.postcode ? ' ' + addressfinder.postcode : ''}`,
                addressfinder.country
            ].forEach(line => {
                if (line) {
                    newAddress.push(line);
                }
            });
            personalChanges.push({
                label: "Address",
                text: newAddress.join(', '),
            });
        }
    }
    
    if (personalChanges.length === 1) {
        return [];
    }
    
    return personalChanges;
}