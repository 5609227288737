export interface DetailsState {
    title: string;
    firstName: string;
    middleNames?: string;
    surname: string;
    gender: string;
    occupation?: string;
    email: string;
    dob: string;
    phone: string;
    cell: string;
    address: {
        line1: string;
        line2?: string;
        suburb?: string;
        city: string;
        country: string;
        postcode: string;
    };
}

type Actions =
    | {
          type: "set_field";
          payload: {
              field: keyof DetailsState;
              value: DetailsState[keyof DetailsState];
          };
      }
    | {
          type: "set_phone";
          payload: {
              field: keyof DetailsState;
              value: DetailsState[keyof DetailsState];
          };
      }
    | {
          type: "set_address";
          payload: {
              field: keyof DetailsState["address"];
              value: DetailsState["address"][keyof DetailsState["address"]];
          };
      };

export function detailsReducer(
    state: DetailsState,
    { type, payload }: Actions
) {
    switch (type) {
        case "set_phone":
            return {
                ...state,
                phone: {
                    [payload.field]: payload.value,
                },
            };
        case "set_field":
            return {
                ...state,
                [payload.field]: payload.value,
            };
        case "set_address":
            return {
                ...state,
                address: {
                    ...state.address,
                    [payload.field]: payload.value,
                },
            };
        default:
            return state;
    }
}
