import { InteractionPayload } from "../../../config/forms/register/payload";
import { Parent } from "../../../components/form/whakapapa/parent-interfaces/interfaces";

export function whakapapaString(fullIdentifier: string): string {
    let identifier = fullIdentifier;
    let ret = "";
    while (identifier.length) {
        const lastTupuna = identifier[identifier.length - 1];
        identifier = identifier.slice(0, identifier.length - 1);
        if (lastTupuna === "w") {
            if (ret) {
                ret = `mother's ${ret}`;
            } else {
                ret = "mother";
            }
        } else if (lastTupuna === "m") {
            if (ret) {
                ret = `father's ${ret}`;
            } else {
                ret = "father";
            }
        } else {
            console.error(
                `Unrecognised whakapapa identifier ${fullIdentifier}`
            );
        }
    }

    return ret[0].toUpperCase() + ret.slice(1);
}

export function addWhakapapa(
    mother: Parent,
    father: Parent,
    base: string = ""
): InteractionPayload[] {
    const whaea = `${base}w`;
    const matua = `${base}m`;
    let list: InteractionPayload[] = [];

    if (mother.name) {
        list.push({ value: `${whakapapaString(whaea)}: ${mother.name}` });
        list = [...list, ...addWhakapapa(mother.mother, mother.father, whaea)];
    }
    if (father.name) {
        list.push({ value: `${whakapapaString(matua)}: ${father.name}` });
        list = [...list, ...addWhakapapa(father.father, father.mother, matua)];
    }

    return list;
}
