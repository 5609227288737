import { PersonRequest } from "@plinknz/tah-website-elements";

import { bankConfig } from "./config-generics/bank-details";
import { personalDetailsConfig } from "./config-generics/personal-details";
import { signatureConfig } from "./config-generics/signature";

export const createSurveyConfig = (member: PersonRequest) => {
    const personalConfig = personalDetailsConfig(member);

    const pages: any[] = [
        {
            name: "guidelines",
            elements: [
                {
                    type: "html",
                    html: "<h3>Award:</h3><ol><li> $75 per year</li><li> Maximum of two applications per year up to a combined total of $75.</li></ol> <h3>Eligibility:</h3><ol><li> Applicants must be over 18 years old. Applications can be made on behalf of registered owners under the age of 18, or a whānau member in their care.</li><li> Person receiving the grant must reside in Aotearoa. </li><li> Person receiving the grant must be a registered Whakarewa member.</li></ol> <h3>Other Requirements:</h3><ol><li> The grant is provided for any services related to their personal health and wellbeing. </li><li> An application for funding can be made after the service has been used and paid. You are required to provide proof of purchase. </li><li> Services (or products) that will be funded are decided on a case-by-case basis.</li></ol>",
                },
            ],
        },
        ...personalConfig,
        ...bankConfig,
        {
            name: "applicant",
            title: "Applicant - Kaitono",
            questions: [
                {
                    type: "radiogroup",
                    name: "relationship",
                    title:
                        "Are you submitting the grant application for another person?",
                    colCount: 0,
                    choices: ["Āe", "Kāo"],
                    isRequired: true,
                },
                {
                    type: "comment",
                    name: "relationshipDetails",
                    visibleIf: "{relationship} = 'Āe'",
                    title: "Please provide your full name and relationship to the Grant Recipient in the space provided below",
                    placeHolder: "Hononga",
                    isRequired: true,
                },
            ],
        },
        {
            name: "grantInformation",
            title: "Request Details - Taipitopito o te Tono",
            questions: [
                {
                    type: "comment",
                    name: "fundedHealthCare",
                    placeHolder: "Whakamarama mai",
                    title: "Provide details of the health service / treatment being funded",
                    isRequired: true,
                },
                {
                    type: "file",
                    name: "receipts",
                    title: "Please upload receipts or proof of purchase for the current year",
                    storeDataAsText: false,
                    allowMultiple: false,
                    maxSize: 10485760,
                    waitForUpload: true,
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "appliedTotal",
                    placeHolder: "Te pūtea",
                    title: "Total amount applied for",
                    startWithNewLine: true,
                    isRequired: true,
                },
            ],
        },
        ...signatureConfig,
    ];

    return { pages };
};