export interface AlignmentData {
    alignment: string;
    involvement?: string;
    balance?: string;
    leadership?: string;
    thesis?: string;
}

interface alignementConfigProps {
    balance?: boolean;
}

export function alignmentConfig({ balance = true}: alignementConfigProps) {
    const config = [{
        name: "empowerment",
        title: "Empowerment - Whakarewa",
        questions: [
            {
                type: "comment",
                name: "involvement",
                title: `
                    Describe your involvement in Whakarewa kaupapa over the
                    past 12 months
                `,
                placeHolder: "Kūtorotanga",
                isRequired: true,
            },
            {
                type: "comment",
                name: "alignment",
                title: `
                    Explain how your programme of study/research contributes
                    to the business objectives or social deliverables of
                    Whakarewa
                `,
                placeHolder: "Hāngaitanga",
                isRequired: true,
            },
        ],
    }];
    
    if (balance) {
        config[0].questions.push({
            type: "comment",
            name: "balance",
            title: `
                Explain how Whakarewa can balance its economic, social
                and cultural objectives
            `,
            placeHolder: "Taurite",
            isRequired: true,
        });
    }
    
    return config;
}

export const researchConfig = [
    {
        name: "empowerment",
        title: "Empowerment - Whakarewa",
        questions: [
            {
                type: "comment",
                name: "alignment",
                title: `
                    Explain how your research/study aligns with Whakarewa's
                    social and cultural objectives, Te Pae Mahutonga
                `,
                placeHolder: "Whakarewatanga",
                isRequired: true,
            },
            {
                type: "comment",
                name: "leadership",
                title: `
                    Provide details of any leadership and representative
                    roles held during the last five years
                `,
                placeHolder: "Rangatiratanga",
                isRequired: true,
            },
            {
                type: "comment",
                name: "thesis",
                title: `
                    Provide proposed thesis title (if applicable) and
                    details
                `,
                placeHolder: "Rangahau",
                isRequired: true,
            },
        ],
    }
];