import { RouteComponentProps } from 'react-router-dom';

import {
    ApiRequest,
    PersonRequest,
    WHATA_ENDPOINTS,
} from "@plinknz/tah-website-elements";

import * as ROUTES from "../config/router";
import { whataRequest } from "../service/member/whata";

export interface fetchUserProps {
    id: string | number;
    history: RouteComponentProps['history'];
    location: RouteComponentProps['location'];
    whata?: ApiRequest;
    dispatch: any;
}

export async function fetchUser ({
    id,
    history,
    location,
    dispatch,
    whata = whataRequest
}: fetchUserProps): Promise<void> {
    if (!id) {
        return;
    }

    try {
        const { data } = await whata.get<PersonRequest | string>(
            `${WHATA_ENDPOINTS.person()}/${id}`
        );

        if (typeof data === "string" && data.includes("Login")) {
            history.push(ROUTES.membersLogout());
            return;
        }

        if (data) {
            dispatch({ type: "set_member", member: data as PersonRequest });
        }
    } catch (error) {
        console.warn(error);

        if (location.pathname !== ROUTES.membersLogin()) {
            history.push(ROUTES.membersLogin());
        }
    }
};
