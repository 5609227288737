import { BankData } from "../../service/forms/config-generics/bank-details";

export function getBankSVGLines({
    bank,
    bankAccountCountry,
    overseasBankCountry,
    overseasBankAddress,
    overseasBankName,
    overseasBankNumber,
    overseasBankSwift,
}: BankData) {
    if (bankAccountCountry === "Aotearoa") {
        return [
            { heading: "Pēke - Bank" },
            { label: "Name", text: bank.name },
            { label: "Number", text: `${bank.bank} ${bank.branch} ${bank.account} ${bank.suffix}`},
        ];
    } else {
        return [
            { heading: "Pēke Tāwāhi - Overseas Bank" },
            { label: "Country", text: overseasBankCountry },
            { label: "Address", text: overseasBankAddress },
            { label: "Name", text: overseasBankName },
            { label: "Number", text: overseasBankNumber },
            { label: "Swift", text: overseasBankSwift },
        ];
    }
}