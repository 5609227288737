export interface TertiaryData {
    institution: string;
    institutionAddress: string;
    course: string;
    courseLength: string;
    yearOfStudy: string;
    subjects: string;
    status: "Full Time" | "Part Time";
    qualifications: string;
    highestQualification?:
        "School Certificate"  |
        "University Entrance" |
        "NCEA 1"              |
        "NCEA 2"              |
        "NCEA 3"              |
        "Other";
}

interface tertiaryInstitutionProps {
    schoolQuals?: boolean;
}

export function tertiaryInstitutionConfig({ schoolQuals = false}: tertiaryInstitutionProps) {
    const lines = [{
        name: "education",
        title: "Study Details - Taipitopito o te Ako",
        questions: [
            {
                type: "text",
                name: "institution",
                title: "Name of Tertiary Institute",
                placeHolder: "Ingoa o te Kura",
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "comment",
                name: "institutionAddress",
                title: "Institute Address",
                placeHolder: "Wāhi Noho o te Kura",
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "text",
                name: "course",
                title: "Course of Study",
                placeHolder: "Akomanga",
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "text",
                name: "courseLength",
                title: "Length of Course",
                placeHolder: "Roanga o te Ako",
                isRequired: true,
            },
        ],
    }, {
        name: "education",
        title: "Study Details - Taipitopito o te Ako",
        questions: [
            {
                type: "text",
                name: "yearOfStudy",
                title: "Year of Study (i.e. 1st, 2nd...)",
                placeHolder: "Tau Ako",
                isRequired: true,
            },
            {
                type: "comment",
                name: "subjects",
                title: "Course Subjects",
                placeHolder: "Kaupapa Ako",
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "radiogroup",
                name: "status",
                title:
                    "Student Status",
                colCount: 0,
                choices: ["Full Time", "Part Time"],
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "comment",
                name: "qualifications",
                title: "Other Qualifications",
                placeHolder: "Ngā Tohu Kē",
                startWithNewLine: true,
                isRequired: false,
            },
        ],
    }];
    
    if (schoolQuals) {
        lines[1].questions.splice(3, 0, ({
            type: "dropdown",
            name: "highestQualification",
            title: "Highest School Qualification",
            choices: [
                "School Certificate",
                "University Entrance",
                "NCEA 1",
                "NCEA 2",
                "NCEA 3",
                "Other"
            ],
            startWithNewLine: true,
            isRequired: true,
        } as any));
    }
    
    return lines;
}

export interface FeesData {
    fees: string;
    costs: string;
    otherGrants: string;
    feesReceipt?: File[];
}

export function feesConfig({ receipt = false }: { receipt?: boolean }) {
    const lines = [
        {
            name: "utu",
            title: "Tuition Fees - Utu Ako",
            questions: [
                {
                    type: "text",
                    name: "fees",
                    placeHolder: "Utu Ako",
                    title: "Tuition Fees",
                    isRequired: true,
                },
                {
                    type: "comment",
                    name: "costs",
                    title: "Additional study costs (e.g. travel)",
                    placeHolder: "Utu Kē",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "comment",
                    name: "otherGrants",
                    placeHolder: "Whiwhinga Kē",
                    title: `
                        Have you applied for, or do you hold any other grants or
                        scholarships? If yes, please provide details of funder,
                        amount and status of application/s.
                    `,
                    startWithNewLine: true,
                    isRequired: true,
                },
            ],
        },
    ];
    
    if (receipt) {
        lines[0].questions.push({
            type: "file",
            name: "feesReceipt",
            title: "Fees receipt if applicable",
            storeDataAsText: false,
            allowMultiple: false,
            maxSize: 10485760,
            waitForUpload: true,
            startWithNewLine: true,
            isRequired: false,
        } as any);
    }
    
    return lines;
}