/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import { PersonRequest } from "@plinknz/tah-website-elements";

import { AddressMeta } from "../../components/form/address-finder-input";
import {
    APPLIED_STATUS,
    BenefitType,
    GrantPayload,
} from "../../config/forms/";
import { getPersonalChanges } from "../svg/member";
import { createSVG } from "../svg/svg";

interface SourceData {
    numberOfPeople: number;
    peopleOverSixty: number;
    peopleUnderFive: number;
    localFoodBank: string;
    winz: string;
    cycloneGabrielle: string;
    cycloneAssistance: string;
    signed: string;
    phone: string;
    email: string;
    addressfinder: AddressMeta;
}

function createSvg({member, data: {
    numberOfPeople,
    peopleOverSixty,
    peopleUnderFive,
    localFoodBank,
    winz,
    cycloneGabrielle,
    cycloneAssistance,
    signed,
    phone,
    email,
    addressfinder,
}}: {member: PersonRequest, data: SourceData}) {
    const personalChanges = getPersonalChanges({
        member,
        phone,
        email,
        addressfinder,
    });
    
    let lines = [
        ...personalChanges,
        { heading: "Te Kāinga" },
        { label: "People in kāinga", text: numberOfPeople },
        { label: "Kaumātua", text: peopleOverSixty },
        { label: "Tamariki under 5", text: peopleUnderFive },
        { heading: "Assistance" },
        { label: "Local Food Bank", text: localFoodBank },
        { label: "WINZ", text: winz },
        { label: "Cyclone Gabrielle", text: cycloneGabrielle },
        { src: signed },
    ];
    
    if (cycloneAssistance) {
        lines.push({ label: "Cyclone Assistance", text: cycloneAssistance.replace(/[\r\n]/g, " ") });
    }
    
    return createSVG({
        lines,
        heading: `${BenefitType.KaiPahara} Application`,
    });
}

export function transformKaiPaharaData(
    survey: Survey.ReactSurveyModel,
    member: PersonRequest,
): GrantPayload {
    const surveyData = survey.data;
    const now = new Date().getTime();
    const interaction = createSvg({data: surveyData, member });

    const data: GrantPayload = {
        data: {
            benefit: {
                benefitDescription: BenefitType.KaiPahara,
                benefitStatus: APPLIED_STATUS,
                benefitDate: now,
            },
            interaction: {
                interaction,
                interactionDate: now,
            },
        },
        recaptcha: null,
        tenant: "NRAIT",
    };

    return data;
}
