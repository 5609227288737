import { PEN_COLOUR } from "../constants";

export interface SignatureData {
    signed: string;
}

export const signatureConfig = [{
    name: "applicantDeclaration",
    title: "Certify - Kī Taurangi",
    questions: [
        {
            name: "signed",
            type: "signaturepad",
            title: `
                Please sign your name on the signature pad to certify that
                the information provided in this form is true and correct.
            `,
            penColor: PEN_COLOUR,
            width: 359,
            isRequired: true,
        },
    ],
}];