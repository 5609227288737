import { PersonRequest } from "@plinknz/tah-website-elements";

import { bankConfig } from "./config-generics/bank-details";
import { feesConfig, tertiaryInstitutionConfig } from "./config-generics/institution";
import { mahiConfig } from "./config-generics/employment";
import { grantGuidelinesConfig } from "./config-generics/grant-gidelines";
import { personalDetailsConfig } from "./config-generics/personal-details";
import { signatureConfig } from "./config-generics/signature";

export const createSurveyConfig = (member: PersonRequest) => {
    const personalConfig = personalDetailsConfig(member);
    const tertiaryDetailsConfig = tertiaryInstitutionConfig({ schoolQuals: true});
    const feesDetailsConfig = feesConfig({ receipt: true });

    const pages: any[] = [
        ...grantGuidelinesConfig,
        ...personalConfig,
        ...bankConfig,
        ...tertiaryDetailsConfig,
        ...feesDetailsConfig,
        ...mahiConfig,
        ...signatureConfig,
    ];

    return { pages };
};