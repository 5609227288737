/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import { PersonRequest } from "@plinknz/tah-website-elements";

import {
    APPLIED_STATUS,
    BenefitType,
    GrantPayload,
} from "../../config/forms/";
import { createSVGNew } from "../svg/svg";

export function transformBenefitFormData(
    survey: Survey.ReactSurveyModel,
    member: PersonRequest,
    benefitType: BenefitType,
): GrantPayload {
    const now = new Date().getTime();
    const interaction = createSVGNew({
        heading: `${benefitType} Application`,
        data: {
            ...survey.data,
            member,
        },
    });

    const data: GrantPayload = {
        data: {
            benefit: {
                benefitDescription: benefitType,
                benefitStatus: APPLIED_STATUS,
                benefitDate: now,
            },
            interaction: {
                interaction,
                interactionDate: now,
            },
        },
        recaptcha: null,
        tenant: "NRAIT",
    };

    return data;
}
