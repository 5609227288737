import { createSVGConfig, SVGSourceData } from "./svg-config";

interface HeadingLine {
    heading: string;
}

interface TextLine {
    label: string;
    text: string | number;
}

interface ImageLine {
    src: string;
}

export type SVGLine = HeadingLine | TextLine | ImageLine;

interface drawSVGProps {
    heading: string;
    lines: SVGLine[];
}
function drawSVG({ heading, lines }: drawSVGProps) {
    let y = 20;
    const yIncr = 20;
    const tab1 = 5;
    const tab2 = 10;
    const tab3 = 150;
    const imgHeight = 200;
    
    const svg = [`<text font-weight="bold" font-size="large" x="${tab1}" y="${y}">${heading}</text>`];
    y += yIncr;
    let headingLast = false;

    for (const rec of lines) {
        if (rec.hasOwnProperty('heading')) {
            if (headingLast) {
                // If the last was a heading, indicates empty content
                svg.pop();
                y -= 2 * yIncr;
            }
            
            y += yIncr;
            svg.push(`<text font-weight="bold" x="${tab1}" y="${y}">${(rec as HeadingLine).heading}</text>`);
            y += yIncr;
            headingLast = true;
        } else if (rec.hasOwnProperty('src')) {
                svg.push(`<image height="${imgHeight}" x="${tab2}" y="${y}" href="${(rec as ImageLine).src}" />`);
                y += imgHeight;
                headingLast = false;
        } else {
            const text = (rec as TextLine).text;
            if (text) {
                const safeText = (text as any).replaceAll(/\r?\n/gi, " ");
                svg.push(`<text x="${tab2}" y="${y}">${(rec as TextLine).label}</text>`);
                svg.push(`<text x="${tab3}" y="${y}">${safeText}</text>`);
                y += yIncr;
                headingLast = false;
            }
        }
    }
    
    svg.push('</svg>');
    svg.unshift(`<svg xmlns="http://www.w3.org/2000/svg" xml:lang="en" height="${y + yIncr}" width="500">`);
    
    return svg.join("");
}

export function createSVG({ lines, heading }: drawSVGProps) {
    return drawSVG({ lines, heading });
}

export function createSVGNew({
    heading,
    data,
}: {
    heading: string,
    data: SVGSourceData
}) {
    const lines = createSVGConfig(data);
    
    const svg = drawSVG({
        lines,
        heading,
    });
    
    return svg;
}