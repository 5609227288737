/* eslint-disable no-param-reassign */
import * as SurveyType from "survey-react";

export function applySurveyConfig(
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    survey: typeof SurveyType
) {
    survey.StylesManager.applyTheme("modern");
    survey.modernCss.root = "form";
    survey.modernCss.row = "form-row";
    survey.modernCss.navigationButton = "button";
    survey.modernCss.dropdown.control = "dropdown";
    survey.modernCss.text.root = "input";
    survey.modernCss.question.formGroup = "form-group";
    survey.modernCss.footer = "form-navigation";
    survey.modernCss.page.title = "heading-4";
    survey.modernCss.page.root = "";
    survey.modernCss.question.title = "heading-6";
    survey.modernCss.question.content = "";
    survey.modernCss.question.mainRoot = "";

    survey.modernCss.progress = "progress";
    survey.modernCss.progressBar = "progress-bar";
    survey.modernCss.progressTextInBar = "progress-text";

    survey.modernCss.navigation.next = "primary next";
    survey.modernCss.navigation.complete = "primary next";
    survey.modernCss.navigation.prev = "tertiary previous";
    survey.modernCss.signaturepad.clearButton = "button tertiary previous";

    survey.modernCss.question.titleOnAnswer = "";

    survey.modernCss.question.mainRoot = "form-group";

    survey.surveyLocalization.locales.my = {
        pageNextText: "Haere / Next",
        pagePrevText: "Hoki / Previous",
        completeText: "Mutu / Complete",
        completingSurvey:
            "Tino Pai - Thank you for your application. We will be in touch in due course",
        progressText: "{0} / {1}",
    };
    survey.FunctionFactory.Instance.register("whakapapaVisible", (params) =>
        Boolean(params[0])
    );
    survey.FunctionFactory.Instance.register("maraeVisible", (params) => {
        if (params?.[0]) {
            return params[0].marae === params[1];
        }

        return false;
    });
}
