/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import { format } from "date-fns";
import {
    InteractionPayload,
    LinkPayload,
    MemberPayload,
    RegisterPayload,
} from "../../config/forms/register/payload";
import { addWhakapapa } from "../../service/forms/register/utilities";

function getGender(gender: string): string {
    if (gender === "Tāne") {
        return "Male";
    }
    if (gender === "Wahine") {
        return "Female";
    }
    return "";
}

export function transformRegistrationFormData(
    survey: Survey.ReactSurveyModel,
    tupunaBaseId: string,
    id: string,
): RegisterPayload {
    const surveyData = survey.data;
    const gender = getGender(surveyData.gender);
    const dob = format(new Date(surveyData.dob), "yyyyMMdd");

    const data: MemberPayload = {
        NRAITId: id,
        title: surveyData.title,
        firstName: surveyData.firstName,
        surname: surveyData.surname,
        gender,
        cell: surveyData.cell,
        phone: surveyData.phone,
        email: surveyData.email,
        occupation: surveyData.occupation,
        line1: surveyData.addressfinder.address_line_1 || "",
        line2: surveyData.addressfinder.address_line_2 || "",
        suburb: surveyData.addressfinder.suburb || "",
        city: surveyData.addressfinder.city || "",
        postcode: surveyData.addressfinder.postcode || "",
        country: surveyData.addressfinder.country || "",
        dob,
        tupunaBaseId,
    };
    let interactions: InteractionPayload[] = [];
    const links: LinkPayload[] = [];

    ["education", "educationDetails", "registrationNumber", "birthplace"].forEach((attribute) => {
        if (surveyData[attribute]) {
            const capitalisedAttribute =
                attribute[0].toUpperCase() + attribute.slice(1);
            const value = `${capitalisedAttribute}: ${surveyData[attribute]}`;

            interactions.push({
                value,
            });
        }
    });
    
    let i = 1;
    let tamaiti = `tamaiti${i}`;
    const dateFormat = "dd/MM/yyyy";

    while (surveyData[`${tamaiti}FirstName`]) {
        const firstName = surveyData[`${tamaiti}FirstName`];
        const surname = surveyData[`${tamaiti}Surname`];
        const gender = surveyData[`${tamaiti}Gender`];
        const dob = format(new Date(surveyData[`${tamaiti}Dob`]), dateFormat);
        const birthplace = surveyData[`${tamaiti}Birthplace`];
        const deceased = surveyData[`${tamaiti}Deceased`];
        const dod = surveyData[`${tamaiti}Dod`];
        const burialPlace = surveyData[`${tamaiti}BurialPlace`];
        
        let deceasedInfo = "";
        if (deceased === "Āe") {
            deceasedInfo = " deceased";
            if (dod) {
                const dodFormatted = format(new Date(dod), dateFormat);
                deceasedInfo += ` ${dodFormatted}`;
            }
            
            if (burialPlace) {
                deceasedInfo += ` buried at ${burialPlace}`;
            }
        }
            
        interactions.push({
            value: `Tamaiti ${i}: ${firstName} ${surname} (${gender}) Born ${dob} ${birthplace}${deceasedInfo}`,
        });
        
        i += 1;
        tamaiti = `tamaiti${i}`;
    }

    if (surveyData.signed) {
        links.push({
            type: "attachment",
            value: surveyData.signed,
        });
    }

    interactions = [
        ...interactions,
        ...addWhakapapa(
            surveyData.whakapapa.mother,
            surveyData.whakapapa.father,
            ""
        ),
    ];

    return { data, interactions, links, recaptcha: null, tenant: "NRAIT" };
}
