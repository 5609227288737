/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import { PersonRequest } from "@plinknz/tah-website-elements";

import {
    APPLIED_STATUS,
    BenefitType,
    GrantPayload,
} from "../../config/forms/";
import { BankData } from "../../service/forms/config-generics/bank-details";
import { PersonalData } from "../../service/forms/config-generics/personal-details";
import { SignatureData } from "../../service/forms/config-generics/signature";

import { getBankSVGLines } from "../svg/bank";
import { getPersonalChanges } from "../svg/member";
import { createSVG } from "../svg/svg";

interface SourceData extends BankData, PersonalData, SignatureData {
    relationship: "Āe" | "Kāo";
    relationshipDetails: string;
    fundedHealthCare: string;
    receipts: File[];
    appliedTotal: string;
}

function generateSVG({member, data }: {member: PersonRequest, data: SourceData}) {
    const personalLines = getPersonalChanges({
        ...data,
        member
    });
    
    const {
        relationship,
        relationshipDetails,
        fundedHealthCare,
        appliedTotal,
        receipts,
        signed,
    } = data;
    
    const bankLines = getBankSVGLines(data);

    const lines = [
        ...personalLines,
        ...bankLines,
    ];
    
    if (relationship === "Āe") {
        lines.push(
            { heading: "Mō Tētahi Atu - Submitting For Someone Else" },
            { label: "Relationship", text: relationshipDetails },
        );
    }
    
    lines.push(
        { heading: "Treatment" },
        { label: "Details", text: fundedHealthCare },
        { label: "Amount", text: appliedTotal },
    );
    
    if (!!receipts[0]) {
        lines.push({ label: "Receipt", text: receipts[0].name });
    }

    lines.push({ src: signed });
    
    return createSVG({
        lines,
        heading: `${BenefitType.Riuwaka} Application`,
    });
}

export function transformRiuwakaData(
    survey: Survey.ReactSurveyModel,
    member: PersonRequest,
): GrantPayload {
    const surveyData = survey.data;
    const now = new Date().getTime();
    const interaction = generateSVG({data: surveyData, member });

    const data: GrantPayload = {
        data: {
            benefit: {
                benefitDescription: BenefitType.Riuwaka,
                benefitStatus: APPLIED_STATUS,
                benefitDate: now,
            },
            interaction: {
                interaction,
                interactionDate: now,
            },
        },
        recaptcha: null,
        tenant: "NRAIT",
    };

    return data;
}
