/* eslint-disable max-lines */
import { ADDRESS_FINDER_KEY } from "../../../components/survey-widgets/address-finder-widget";
import { DATEPICKER_KEY } from "../../../components/survey-widgets/datepicker-widget";
import { WHAKAPAPA_KEY } from "../../../components/survey-widgets/whakapapa-widget";
import { getTamarikiList } from "../../../config/forms/register/tamariki";
import { PEN_COLOUR } from "../constants";

export async function createSurveyConfig({
    tupunaChoices,
}: {
    tupunaChoices: string[];
}) {
    const tamarikiList = getTamarikiList();
    return {
        pages: [
            {
                name: "intro",
                title: "Become an Owner",
                elements: [
                    {
                        type: "html",
                        html: `The beneficiaries of this Trust shall be those Māori people comprising members of the Ngāti Rārua and Te Ātiawa manawhenua ki Motueka tribes who can establish a direct lineal descent (by birth or adoption including either formal legal adoption or customary Maori adoption) and their descendants and families.<br/><br/>If you belong to this group, please fill in the form below to register with the Trust.`,
                    },
                ],
            },
            {
                name: "personalDetails",
                title: "Personal Details - Taipitopito Matawhaiaro",
                elements: [
                    {
                        type: "dropdown",
                        name: "title",
                        title: "Title",
                        choices: ["Mrs", "Mr", "Miss", "Ms", "Mx", "Dr"],
                    },
                    {
                        type: "text",
                        name: "firstName",
                        title: "First Name(s)",
                        placeHolder: "Ingoa",
                        isRequired: true,
                    },
                    {
                        type: "text",
                        name: "surname",
                        title: "Surname",
                        placeHolder: "Ingoa Whānau",
                        startWithNewLine: false,
                        isRequired: true,
                    },
                    {
                        type: "text",
                        name: "registrationNumber",
                        title: "Parent Registration Number (if applying for tamaiti)",
                        placeHolder: "Nama Rēhita",
                        startWithNewLine: true,
                    },
                    {
                        type: "radiogroup",
                        name: "gender",
                        title: "Gender",
                        choices: ["Wahine", "Tāne", "Ira Weherua-kore"],
                        colCount: 0,
                        startWithNewLine: false,
                        isRequired: true,
                    },
                    {
                        type: DATEPICKER_KEY,
                        inputType: "date",
                        name: "dob",
                        title: "Birthdate",
                        startWithNewLine: true,
                        isRequired: true,
                    },
                    {
                        type: "text",
                        name: "birthplace",
                        title: "Birthplace",
                        placeHolder: "Wāhi Whānau",
                        isRequired: true,
                        startWithNewLine: false,
                    },
                ],
            },
            {
                name: "address",
                title: "Address - Wāhi Noho",
                questions: [
                    {
                        title: "Address",
                        type: ADDRESS_FINDER_KEY,
                        name: "addressfinder",
                        isRequired: true,
                    },
                ],
            },
            {
                name: "contactDetails",
                title: "Contact Details - Taipitopito Whakapā",
                questions: [
                    {
                        title: "Mobile",
                        type: "text",
                        name: "cell",
                        placeHolder: "Waea Pūkoro",
                        startWithNewLine: false,
                        isRequired: true,
                    },
                    {
                        title: "Phone",
                        type: "text",
                        name: "phone",
                        placeHolder: "Waea",
                        startWithNewLine: false,
                    },
                    {
                        title: "Email",
                        type: "text",
                        name: "email",
                        placeHolder: "Īmeera",
                        validators: [
                            {
                                type: "email",
                            },
                        ],
                        startWithNewLine: true,
                        isRequired: true,
                    },
                ],
            },
            {
                name: "tamariki",
                title: "Children - Tamariki",
                questions: tamarikiList,
            },
            {
                name: "occupation",
                title: "Occupation and Education - Mahi me te Mātauranga",
                questions: [
                    {
                        type: "dropdown",
                        name: "education",
                        title: "Education",
                        choices: [
                            "Apprenticeship",
                            "Tertiary",
                            "Degree",
                            "Masters",
                            "Trade",
                            "Other",
                        ],
                    },
                    {
                        type: "text",
                        name: "educationDetails",
                        title: "Details",
                        placeHolder: "Taipitopito",
                        startWithNewLine: false,
                    },
                    {
                        type: "text",
                        name: "occupation",
                        title: "Occupation",
                        placeHolder: "Mahi",
                        startWithNewLine: true,
                    },
                ],
            },
            {
                name: "whakapapa",
                title: "Your Whakapapa",
                questions: [
                    {
                        name: "tupuna",
                        title: "Tupuna",
                        type: "dropdown",
                        isRequired: true,
                        choices: tupunaChoices,
                    },
                    {
                        title: "Include both parents and (only) your line of descent from your Whakarewa Tupuna.",
                        type: WHAKAPAPA_KEY,
                        name: "whakapapa",
                        isRequired: true,
                    },
                ],
            },
            {
                name: "privacy",
                title: "Declaration & Privacy - Tauākī me te Noho Matatapu",
                questions: [
                    {
                        name: "tupunaAcknowledgement",
                        title: `Your application must show the line of descent of each generation to your
                        original owner and cannot be forwarded to our Kōmiti Whakapapa for approval until
                        this information is provided. Please check that you have fully completed this
                        section of the form.`,
                        type: "checkbox",
                        choices: ["Āe"],
                        isRequired: true,
                    },
                    {
                        name: "privacyAcknowledgement",
                        title:
                            "I understand that the information I provide is subject to the Privacy Act 1993",
                        type: "checkbox",
                        choices: ["Āe"],
                        isRequired: true,
                    },
                    {
                        name: "signed",
                        type: "signaturepad",
                        title: "Please sign your name on the signature pad",
                        penColor: PEN_COLOUR,
                        width: 359,
                        isRequired: true,
                    },                ],
            },
        ],
    };
}
