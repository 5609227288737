import { FeesData, TertiaryData } from "../../service/forms/config-generics/institution";
import { SchoolData } from "../../service/forms/config-generics/school";

export function getInstitutionSVGLines({
    course,
    courseLength,
    highestQualification,
    institution,
    institutionAddress,
    status,
    subjects,
    qualifications,
    yearOfStudy,
}: TertiaryData) {
    return [
        { heading: "Institution" },
        { label: "Institution", text: institution },
        { label: "Address", text: institutionAddress },
        { heading: "Course" },
        { label: "Course", text: course },
        { label: "Length", text: courseLength },
        { label: "Year", text: yearOfStudy },
        { label: "Subjects", text: subjects },
        { label: "Status", text: status },
        { heading: "Qualifications" },
        { label: "Other Qualifications", text: qualifications },
        { label: "School Qualification ", text: highestQualification },
    ];
}

export function getFeesSVGLines({ costs, fees, otherGrants, feesReceipt }: FeesData){
    const lines = [
        { heading: "Financial" },
        { label: "Fees", text: fees },
        { label: "Other Costs", text: costs },
        { label: "Other Grants", text: otherGrants },
    ];
    
    if (feesReceipt?.length) {
        lines.push({ label: "Fees Receipt", text: feesReceipt[0].name });
    }
    
    return lines;
}

export function getSchoolSVGLines({ applicant, school, schoolAddress, schoolFeeUse, schoolFeesReceipt, schoolPhoto }: SchoolData) {
    const lines = [
        { heading: "School" },
        { label: "School", text: school },
        { label: "Address", text: schoolAddress },
        { label: "Funding Use", text: schoolFeeUse },
        { heading: "Uploads" }
    ];

    if (applicant) {
        lines.unshift(
            { heading: "Child Applicant" },
            { label: "Applicant", text: applicant }
        );
    }
    
    if (schoolFeesReceipt?.length) {
        lines.push({ label: "Fees Receipt", text: schoolFeesReceipt[0].name });
    }

    if (schoolPhoto?.length) {
        lines.push({ label: "Photo", text: schoolPhoto[0].name });
    }
    
    return lines;
}