import { PersonRequest } from "@plinknz/tah-website-elements";

import { alignmentConfig } from "./config-generics/alignment";
import { bankConfig } from "./config-generics/bank-details";
import { mahiConfig } from "./config-generics/employment";
import { feesConfig, tertiaryInstitutionConfig } from "./config-generics/institution";
import { personalDetailsConfig } from "./config-generics/personal-details";
import { scholarshipGuidelinesConfig } from "./config-generics/scholarship-guidelines";
import { signatureConfig } from "./config-generics/signature";

export const createSurveyConfig = (member: PersonRequest) => {
    const personalConfig = personalDetailsConfig(member);
    const tertiaryDetailsConfig = tertiaryInstitutionConfig({ schoolQuals: true});
    const feesDetailsConfig = feesConfig({});
    const alignmentDetailsConfig = alignmentConfig({ balance: false });
    const guidelinesConfig = scholarshipGuidelinesConfig({});

    const pages: any[] = [
        ...guidelinesConfig,
        ...personalConfig,
        ...bankConfig,
        ...tertiaryDetailsConfig,
        ...alignmentDetailsConfig,
        ...feesDetailsConfig,
        ...mahiConfig,
        ...signatureConfig,
    ];
    
    return { pages };
};