import { BANK_ACCOUNT_KEY } from "../../../components/survey-widgets/nz-bank-account-widget";

import { BankAccountType } from "../../../components/form/bank-account";

export interface BankData {
    bankAccountCountry: "Aotearoa" | "Overseas";
    overseasBankCountry: string;
    overseasBankSwift: string;
    overseasBankName: string;
    overseasBankNumber: string;
    overseasBankAddress: string;
    bank: BankAccountType;
}

export const bankConfig = [
    {
        name: "bankAccountCountry",
        title: "Bank Account - Pēke",
        questions: [
            {
                name: "bankAccountCountry",
                title: "Do you have a New Zealand or an Overseas bank account?",
                type: "radiogroup",
                choices: ["Aotearoa", "Overseas"],
                isRequired: true,
            },
        ],
    },
    {
        name: "overseasBankAccount",
        title: "Overseas Bank Account - Pēke Tāwahi",
        visibleIf: "{bankAccountCountry} = 'Overseas'",
        questions: [
            {
                type: "text",
                name: "overseasBankCountry",
                title: "Country of Residence",
                placeHolder: "Motu Pēke",
                startWithNewLine: false,
                isRequired: true,
            },
            {
                type: "text",
                name: "overseasBankSwift",
                title: "SWIFT Code",
                placeHolder: "Nama SWIFT",
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "text",
                name: "overseasBankName",
                title: "Account Name (must match your bank statement)",
                placeHolder: "Ingoa Pēke",
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "text",
                name: "overseasBankNumber",
                title: "Account Number",
                placeHolder: "Nama Pēke",
                startWithNewLine: true,
                isRequired: true,
            },
            {
                type: "text",
                name: "overseasBankAddress",
                title: "Bank Address (must match your bank statement)",
                placeHolder: "Wāhi Noho o te Pēke",
                startWithNewLine: true,
                isRequired: true,
            },
        ],
    },
    {
        name: "bankDetails",
        title: "Bank Details - Taipitopito o te Pēke",
        visibleIf: "{bankAccountCountry} = 'Aotearoa'",
        questions: [
            {
                type: BANK_ACCOUNT_KEY,
                name: "bank",
                title: "Name on Bank Account",
                startWithNewLine: false,
                isRequired: false,
                validators: [
                    {
                        type: "expression",
                        expression: "validBankAccount({bank}) = true",
                        text: "Please enter a bank account name and a valid number",
                    },
                ],
            },
        ],
    },
];
