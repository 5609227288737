import { PersonRequest } from "@plinknz/tah-website-elements";

import { personalDetailsConfig } from "./config-generics/personal-details";
import { signatureConfig } from "./config-generics/signature";

export const createSurveyConfig = (member: PersonRequest) => {
    const personalConfig = personalDetailsConfig(member);

    const pages: any[] = [
        {
            name: "guidelines",
            elements: [
                {
                    type: "html",
                    html: "<h3>Please read the following guidelines</h3>1. The Kai Pāhara Grant will be made to registered owners up to the value of $200.<br>2. You can apply once per year per household.<br>3. The grant will be limited to a food parcel however discretion will be allowed dependent on individual circumstances.<br>4. Owners should have explored/used all government options including WINZ, Iwi benefits and food banks available to them before applying.<br> 5. Whakarewa will place a grocery order, based on food bank standard items, and organise for it to be delivered to the owners’ house.",
                },
            ],
        },
        {
            name: "consent",
            elements: [
                {
                    name: "consent",
                    title: "Consent",
                    type: "html",
                    html: "<h3>Consent</h3>On receipt of this application, Whakarewa staff will review all information received to assess the level of support to be provided.<br><br> If your application is to assist with costs associated with Cyclone Gabrielle, please add further information to your application detailing damage to house, relocation costs etc.<br><br> Whakarewa will keep all applications on file.<br><br> All information collected by Whakarewa will remain confidential, in accordance with the provisions of the Privacy Act 1993.",
                }
            ],
        },
        ...personalConfig,
        {
            name: "householdInformation",
            title: "Household Information - Te Kāinga",
            questions: [
                {
                    type: "text",
                    name: "numberOfPeople",
                    title:
                        "What is the total number of people (including yourself) in your whare?",
                    placeHolder: "Tokohia?",
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "peopleOverSixty",
                    title:
                        "How many people in your whare (including yourself) are aged over 60?",
                    placeHolder: "Tokohia?",
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "peopleUnderFive",
                    title:
                        "How many people in your whare are aged 5 or below?",
                    placeHolder: "Tokohia?",
                    isRequired: true,
                },
            ],
        },
        {
            name: "tautoko",
            title: "Assistance Sought - Tautoko",
            questions: [
                {
                    type: "radiogroup",
                    name: "localFoodBank",
                    title:
                        "Have you sought assistance from a local food bank?",
                    colCount: 0,
                    choices: ["Āe", "Kāo"],
                    isRequired: true,
                },
                {
                    type: "radiogroup",
                    name: "winz",
                    title:
                        "Have you sought assistance from WINZ?",
                    choices: ["Āe", "Kāo"],
                    colCount: 0,
                    isRequired: true,
                },
                {
                    type: "radiogroup",
                    name: "cycloneGabrielle",
                    title:
                        "Are you seeking assistance due to Cyclone Gabrielle?",
                    choices: ["Āe", "Kāo"],
                    colCount: 0,
                    isRequired: true,
                },
                {
                    type: "comment",
                    name: "cycloneAssistance",
                    visibleIf: "{cycloneGabrielle} = 'Āe'",
                    title: "Please advise of hardship suffered and what assistance is required for, e.g. relocation costs, travel etc...",
                    placeHolder: "Ōu taumahatanga",
                    isRequired: true,
                },
            ],
        },
        ...signatureConfig,
    ];

    return { pages };
};