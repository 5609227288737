import { PersonRequest } from "@plinknz/tah-website-elements";

import { AddressMeta } from "../../../components/form/address-finder-input";
import { ADDRESS_FINDER_KEY } from "../../../components/survey-widgets/address-finder-widget";

export interface PersonalData {
    phone: string;
    email: string;
    addressfinder: AddressMeta;
}

export function personalDetailsConfig(member: PersonRequest) {
    let defaultValue;
    
    if (member?.address && member?.address.length) {
        const address = member?.address[0];
        defaultValue = {
            address_line_1: address.line1,
            address_line_2: address.line2,
            suburb: address.suburb,
            city: address.city,
            postcode: address.postcode,
            address: address.state,
            country:address.country,
        };
    }
    
    return [
        {
            name: "applicantsDetails",
            title: "Applicant's Details - Taipitopito o te Kaitono",
            elements: [
                {
                    type: "text",
                    name: "firstName",
                    title: "First Name",
                    placeHolder: "Ingoa",
                    defaultValue: member.firstName,
                    readOnly: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "surname",
                    title: "Surname",
                    placeHolder: "Ingoa Whānau",
                    defaultValue: member.surname,
                    readOnly: true,
                    startWithNewLine: false,
                    isRequired: true,
                },
                {
                    type: "text",
                    title: "Phone",
                    name: "phone",
                    placeHolder: "Waea",
                    startWithNewLine: false,
                    defaultValue: member?.phone?.find((e) => e.typeId === "PH" || "PM")?.value,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "email",
                    title: "Email",
                    placeHolder: "Īmēra",
                    validators: [
                        {
                            type: "email",
                        },
                    ],
                    startWithNewLine: true,
                    defaultValue: member?.email?.length ? member.email[0].value: undefined,
                    isRequired: true,
                },
            ],
        },
        {
            name: "address",
            title: "Address - Wāhi Noho",
            questions: [{
                title: "Address",
                type: ADDRESS_FINDER_KEY,
                name: "addressfinder",
                isRequired: true,
                defaultValue,
                },
            ],
        },
    ];
}