import * as React from "react";
import { formatAsSlug } from "@plinknz/tah-website-elements";

export function useScript(
    node: React.MutableRefObject<unknown>,
    scriptSrc: string
): boolean[] {
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const id = formatAsSlug(scriptSrc);

    React.useEffect(() => {
        let script: HTMLScriptElement;

        if (node.current && !scriptLoaded && !document.getElementById(id)) {
            script = document.createElement("script");
            script.id = id;
            script.src = scriptSrc;
            script.async = true;
            script.addEventListener("load", () => {
                if (node.current) {
                    setScriptLoaded(true);
                }
            });
            document.body.appendChild(script);
        }

        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [node]);

    return [scriptLoaded];
}
