export const grantGuidelinesConfig = [{
    name: "guidelines",
    elements: [
        {
            type: "html",
            html: `
<h3>Other Awards</h3>
<div>
  If at any stage during the year you receive another scholarship/award you
  must declare this to Whakarewa.  In this instance we reserve the right to
  reassess any grants that are awarded.
</div>
<br/>
<h3>Specified Course of Study</h3>
<div>
  Whakarewa is sponsoring the course and papers you outline on your application
  form. Any changes you decide to make during the year must be declared to
  Whakarewa and we reserve the right to reassess any grants that are awarded.
</div>
<br/>
<div>
<h3>Full Time Equivalent</h3>
  Payment of any award granted to a whānau member who is completing their
  studies on a part time basis will be subject to a pro rata payment of the
  award based on number of papers being undertaken in each year.<div>
</div>
            `,
        },
    ],
}];