import { PersonRequest } from "@plinknz/tah-website-elements";
import * as React from "react";

export interface UserData {
    id: string;
    name: string;
    email: string;
    onSameEmail: {
        id: number;
        firstName: string;
        surname: string;
    }[]
}

export interface UserState {
    user: UserData;
    whataToken: string;
    strapiToken: string;
    loading: boolean;
    isLoggedIn: boolean;
    resetSent: boolean;
    passwordCreated: boolean;
    error: string;
    member: PersonRequest;
}

const DEFAULT_STATE: UserState = {
    error: null,
    loading: false,
    isLoggedIn: false,
    user: null,
    strapiToken: null,
    whataToken: null,
    resetSent: false,
    passwordCreated: false,
    member: null,
};

interface UserFeature {
    state: UserState;
    dispatch: React.Dispatch<UserActions>;
}

export const USER_KEY = "user";
export const UserContext = React.createContext<UserFeature>({
    state: DEFAULT_STATE,
    dispatch: () => undefined,
});

export type UserActions =
    | { type: "reset" }
    | { type: "update_user"; user: UserData }
    | { type: "loading"; isLoading: boolean }
    | { type: "set_logged_in"; isLoggedIn: boolean }
    | { type: "set_reset_sent"; resetSent: boolean }
    | { type: "set_password_sent"; passwordCreated: boolean }
    | { type: "error"; error: Error | unknown }
    | {
          type: "set_tokens";
          payload: {
              strapiToken: string;
              whataToken: string;
          };
      }
    | { type: "set_member"; member: PersonRequest };

export const initialUserState = (): UserState => {
    try {
        const storedUser = JSON.parse(
            localStorage.getItem(USER_KEY)
        ) as UserState;

        if (storedUser) {
            return storedUser;
        }

        throw new Error();
    } catch (error: unknown) {
        return DEFAULT_STATE;
    }
};

export function userReducer(state: UserState, action: UserActions): UserState {
    switch (action.type) {
        case "loading":
            return {
                ...state,
                loading: action.isLoading,
            };
        case "update_user":
            return {
                ...state,
                error: null,
                user: action.user,
            };
        case "set_tokens":
            return {
                ...state,
                ...action.payload,
            };
        case "set_logged_in":
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
            };
        case "set_reset_sent":
            return {
                ...state,
                resetSent: action.resetSent,
            };
        case "set_password_sent":
            return {
                ...state,
                passwordCreated: action.passwordCreated,
            };
        case "error": {
            let error: string = null;

            if (action?.error instanceof Error) {
                error = action.error.message;
            } else if (typeof action?.error === "string") {
                error = action.error;
            }

            return {
                ...state,
                error,
            };
        }
        case "set_member": {
            return {
                ...state,
                member: action.member,
            };
        }
        case "reset":
            return DEFAULT_STATE;
        default:
            return state;
    }
}
