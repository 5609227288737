import { Contact } from "@plinknz/tah-website-elements";

export enum PhoneType {
    Home = 'PH',
    Cell = 'PM',
}

interface getPhoneProps {
    phone: Contact[];
    type: PhoneType;
}

export function getPhone({ phone, type }: getPhoneProps) {
    if (!phone) {
        return "";
    }
    return phone.find((e) => e.typeId === type)?.value
}