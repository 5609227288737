interface scholarshipGuidelinesProps {
    project?: boolean;
}

export function scholarshipGuidelinesConfig({ project = false}: scholarshipGuidelinesProps) {
    const lines = [
    `
      Present to our members at an annual hui as requested. This is to provide
      information on general progress and the benefits of the scheme so that
      our whānau can understand the value of their contribution.
    `, `
      Expectation of participation in Whakarewa activities during or after study.
    `, `
      Let us know if at any stage during the year you receive another
      scholarship/award. We reserve the right to reassess any scholarships that
      are awarded.
    `, `
      Whakarewa is sponsoring the course and papers you outline on your
      application form. Any changes made during the year must be declared to us
      and we reserve the right to reassess any scholarships that are awarded.
    `, `
      Successful applicants must sign and return a funding agreement.
    `, `
      The award is paid out annually, 50% upon provision of official academic
      record indicating successful completion of semester 1, and the balance on
      provision of official academic record indicating successful completion of
      semester 2.
    `, `
      If you are completing your studies on a part time basis, payment of any
      award granted will be subject to a pro rata payment based on the number of
      papers being undertaken in each year.
    `
    ];
    
    if (project) {
        lines.unshift('Work with Whakarewa on a mutually agreed project.');
    }
    return [
        {
            name: "guidelines",
            elements: [
                {
                    type: "html",
                    html: `
<div>
    <h3>Successful applicants must:</h3>
    <ol>
        ${ lines.map((line) => `<li>${ line }</li>`).join('') }
    </ol>
</div>`,
                },
            ],
        }
    ];
}