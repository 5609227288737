import { PersonRequest } from "@plinknz/tah-website-elements";
import { bankConfig } from "./config-generics/bank-details";
import { personalDetailsConfig } from "./config-generics/personal-details";
import { schoolGrantGuidelinesConfig, schoolConfig } from "./config-generics/school";
import { signatureConfig } from "./config-generics/signature";
import { UserData } from "../reducers/user";

export const createSurveyConfig = (member: PersonRequest, user?: UserData) => {
    const personalConfig = personalDetailsConfig(member);
    const guidelinesConfig = schoolGrantGuidelinesConfig({
        schoolType: 'Primary',
        value: '$100',
    });

    const schoolDetailsConfig = schoolConfig({
        children: user.onSameEmail,
        parent: user.name,
    });

    const pages: any[] = [
        ...guidelinesConfig,
        ...personalConfig,
        ...bankConfig,
        ...schoolDetailsConfig,
        ...signatureConfig,
    ];

    return { pages };
};