import { PersonRequest } from "@plinknz/tah-website-elements";

import { getAlignmentSVGLines } from "./alignment";
import { getBankSVGLines } from "./bank";
import { getEmploymentLines } from "./employment";
import { getEmpowermentSVGLines } from "./empowerment";
import { getFeesSVGLines, getInstitutionSVGLines, getSchoolSVGLines } from "./institution";
import { getPersonalChanges } from "./member";
import { getSignatureSVGLines } from "./signature";

import { AlignmentData } from "../../service/forms/config-generics/alignment";
import { BankData } from "../../service/forms/config-generics/bank-details";
import { FeesData, TertiaryData } from "../../service/forms/config-generics/institution";
import { MahiData } from "../../service/forms/config-generics/employment";
import { EmpowermentData } from "../../service/forms/config-generics/empowerment";
import { PersonalData } from "../../service/forms/config-generics/personal-details";
import { SchoolData } from "../../service/forms/config-generics/school";
import { SignatureData } from "../../service/forms/config-generics/signature";

export interface SVGSourceData extends
    AlignmentData,
    BankData,
    EmpowermentData,
    FeesData,
    MahiData,
    PersonalData,
    SchoolData,
    SignatureData,
    TertiaryData {
    member: PersonRequest;
}

export function createSVGConfig(data: SVGSourceData) {
    const personalLines = getPersonalChanges(data);
    const bankLines = getBankSVGLines(data);
    const institutionLines = getInstitutionSVGLines(data);
    const empowermentLines = getEmpowermentSVGLines(data);
    const feesLines = getFeesSVGLines(data);
    const mahiLines = getEmploymentLines(data);
    const signatureLines = getSignatureSVGLines(data);
    const alignmentLines = getAlignmentSVGLines(data);
    const schoolLines = getSchoolSVGLines(data);
    
    return [
        ...personalLines,
        ...bankLines,
        ...institutionLines,
        ...empowermentLines,
        ...alignmentLines,
        ...feesLines,
        ...mahiLines,
        ...schoolLines,
        ...signatureLines,
    ];
}