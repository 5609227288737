export interface MahiData {
    mahi: string;
    pūteaMahi: string;
}

export const mahiConfig =
    [{
        name: "mahi",
        title: "Employment - Mahi",
        questions: [
            {
                type: "comment",
                name: "mahi",
                title: "Name and Address of Employer/s",
                placeHolder: "Mahi",
                isRequired: true,
            },
            {
                type: "text",
                name: "pūteaMahi",
                placeHolder: "Pūtea Mahi",
                title: `
                    What is your expected income from all sources other than
                    student load or student allowance this year?
                `,
                startWithNewLine: true,
                isRequired: true,
            },
        ],
    }];