import { AlignmentData } from "../../service/forms/config-generics/alignment";

export function getAlignmentSVGLines({ involvement, alignment, balance, leadership, thesis }: AlignmentData) {
    return [
        { heading: "Alignment" },
        { label: "Involvement", text: involvement },
        { label: "Alignment", text: alignment },
        { label: "Balance", text: balance },
        { label: "Leadership", text: leadership },
        { label: "Thesis", text: thesis },
    ];
}