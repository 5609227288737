export interface EmpowermentData {
    whakarewa: string;
}

export const empowermentConfig = [{
        name: "empowerment",
        title: "Empowerment - Whakarewa",
        questions: [
            {
                type: "comment",
                name: "whakarewa",
                title: "How will your study empower you, your whānau and your hapū",
                placeHolder: "Whakarewatanga",
                isRequired: true,
            },
        ],
    }
];